import { SearchParams, SearchResult } from "../../entities"
import { Holiday } from "../../entities/csg/Holiday"
import { HolidayRepository } from "../../repositories/csg/HolidayRepository"
import UserStorage from "../../util/userStorage"

export class HolidayService {
  public static async search(
    params: SearchParams
  ): Promise<SearchResult<Holiday>> {
    const token = UserStorage.getToken()

    const result = await HolidayRepository.search(params, token)
    return result
  }

  public static async get(id: number): Promise<Holiday> {
    const token = UserStorage.getToken()
    return await HolidayRepository.get(id, token)
  }

  public static async create(data: Holiday): Promise<Holiday> {
    const token = UserStorage.getToken()
    return await HolidayRepository.create(data, token)
  }

  public static async update(data: Holiday): Promise<Holiday> {
    const token = UserStorage.getToken()
    return await HolidayRepository.update(data, token)
  }

  public static async delete(id: string): Promise<boolean> {
    const token = UserStorage.getToken()
    return await HolidayRepository.delete(id, token)
  }
}
