import {
  BsBuildingCheck,
  BsCarFrontFill,
  BsCash,
  BsCoin,
  BsCreditCard2Front,
  BsDoorOpenFill,
  BsFillQuestionCircleFill,
  BsFillShieldFill,
  BsHouseDoorFill,
  BsPersonCircle,
  BsPersonFill,
} from "react-icons/bs"
import { FaUmbrellaBeach } from "react-icons/fa6"

const menuItems = [
  {
    title: "Dashboard",
    to: "/dashboard",
    icon: <BsHouseDoorFill />,
  },
  {
    title: "Clientes",
    to: "/customer",
    icon: <BsPersonFill />,
  },
  {
    title: "Cobranças",
    to: "/charge",
    icon: <BsCash />,
  },
  {
    title: "Pagamentos",
    to: "/payment",
    icon: <BsCreditCard2Front />,
  },
  {
    title: "Pagamentos BSO",
    to: "/payment/bso",
    icon: <BsBuildingCheck />,
  },
  {
    title: "Transferências",
    to: "/vehicle/transfer",
    icon: <BsCarFrontFill />,
  },
  {
    title: "Resgate de Saldo",
    to: "/balance-return",
    icon: <BsCoin />,
  },
  {
    title: "Usuários",
    to: "/user",
    icon: <BsPersonCircle />,
  },
  {
    title: "Segurança",
    to: "/security",
    icon: <BsFillShieldFill />,
  },
  {
    title: "Feriados",
    to: "/holiday",
    icon: <FaUmbrellaBeach />,
  },
  {
    title: "Ajuda",
    to: "/help",
    icon: <BsFillQuestionCircleFill />,
  },
  {
    title: "Sair",
    to: "/login",
    icon: <BsDoorOpenFill />,
  },
]

export default menuItems
