import { useState } from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import {
  Flex,
  Icon,
  ListItem,
  Text,
  UnorderedList,
  Link as ChakraLink,
} from "@chakra-ui/react"
import { TbLayoutSidebarRightCollapseFilled } from "react-icons/tb"

import "./SidebarDropdown.scss"
import theme from "../../../config/Themes"
import config from "../../../config/Config"

function SidebarDropdown() {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)

  return (
    <Flex
      className={
        isSidebarCollapsed === true ? "sidebar" : "sidebar sidebar-collapsed"
      }
      w={isSidebarCollapsed === true ? "256px" : "112px"}
      direction="column"
      gap="0.625rem"
      bg="#ffffff"
      p={isSidebarCollapsed === true ? "1rem" : "0.5rem"}
      borderRadius="0.5rem"
      position="relative"
      transition="all 0.3s"
    >
      <Flex className="nav" flex="1" direction="column">
        <Flex className="menu" direction="column" w="100%">
          <Flex
            className="head"
            pb="1.25rem"
            borderBottom="1px solid #f6f6f6"
            justifyContent={
              isSidebarCollapsed === true ? "flex-start" : "center"
            }
          >
            <Flex
              ml={isSidebarCollapsed === true ? "0.35rem" : "0rem"}
              w="2.5rem"
              h="2.5rem"
              borderRadius="0.5rem"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              color="#8E99AB"
              fontSize="1.25rem"
              border="2px solid #f6f6f6"
              bg="#ffffff"
              transition="all 0.3s"
              transform={isSidebarCollapsed === true ? "rotate(180deg)" : ""}
              _hover={{ color: `${theme.color.tertiary}` }}
              onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
            >
              <TbLayoutSidebarRightCollapseFilled />
            </Flex>
          </Flex>
          <UnorderedList w="100%" pt="1rem">
            {config.menuItems.map((item) => (
              <ListItem w="100%" mb="0.3125rem" key={item.title}>
                <ChakraLink
                  as={ReactRouterLink}
                  to={item.to}
                  gap="1rem"
                  display="flex"
                  alignItems="center"
                  p="0.5rem 1rem"
                  className="sidebar-link"
                  transition="ease-in-out 0.2s"
                  pb="0.5rem"
                  _hover={{
                    textDecoration: "none",
                  }}
                >
                  <Icon
                    as="i"
                    className="icon"
                    transition="ease-in-out 0.2s"
                    fontSize="20px"
                    color="#8E99AB"
                  >
                    {item.icon}
                  </Icon>
                  <Text
                    as="span"
                    className="text title"
                    transition="ease-in-out 0.2s"
                    fontFamily="DM Sans"
                    color="#8E99AB"
                    fontSize="1rem"
                    fontWeight="400"
                    lineHeight="1.75rem"
                  >
                    {item.title}
                  </Text>
                </ChakraLink>
              </ListItem>
            ))}
          </UnorderedList>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SidebarDropdown
