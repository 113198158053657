import React, { useEffect, useState } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"

import Layout from "../../../../components/layout/Layout"
import Breadcrumbs from "../../../../components/layout/breadcrumbs/Breadcrumbs"
import {
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Flex,
  SimpleGrid,
  Button,
  useToast,
} from "@chakra-ui/react"
import { AiOutlineArrowLeft } from "react-icons/ai"

import { ValidationError, ValidationErrorHandling } from "../../../../util"

import theme from "../../../../config/Themes"
import { HolidayService } from "../../../../services/csg/HolidayService"
import { Holiday } from "../../../../entities/csg/Holiday"
import Loader from "../../../../components/Loader/Loader"
import { format, isValid } from "date-fns"
import Formatter from "../../../../util/formatter"

export default function HolidayFormPage() {
  const navigate = useNavigate()
  const params = useParams()
  const toast = useToast()

  const id = params?.id ? parseInt(params?.id) : 0
  const updateMode = params?.id && parseInt(params?.id) != 0

  const [form, setForm] = useState(new Holiday())

  const [errors, setErrors] = useState<ValidationError[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (updateMode) load(id)
  }, [params.id])

  const load = (id: number) => {
    setLoading(true)
    HolidayService.get(id)
      .then((data) => setForm(data))
      .finally(() => setLoading(false))
  }

  const onSubmit = () => {
    const holiday = new Holiday(form)

    if (!holiday.name)
      return toast({
        title: "Digite o nome do feriado.",
        status: "error",
        duration: 2000,
      })

    if (!form.date)
      return toast({
        title: "Digite a data do feriado.",
        status: "error",
        duration: 2000,
      })

    if (!updateMode) {
      setLoading(true)
      HolidayService.create(form)
        .then((data) => {
          setForm(data)
          navigate(`/holiday`)
        })
        .finally(() => setLoading(false))
    } else {
      setLoading(true)
      HolidayService.update(form)
        .then((data) => {
          setForm(data)
          navigate(`/holiday`)
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <Layout>
      {loading && <Loader />}
      <Breadcrumbs
        items={[
          { label: "Dashboard", link: "/" },
          { label: "Feriados", link: "/holiday" },
          { label: "Cadastro de Feriados", link: `/holiday/${form.id}` },
        ]}
        additionalHeaderInfo={
          <Link to="/holiday">
            <button>
              <AiOutlineArrowLeft />
            </button>
          </Link>
        }
      />
      <Flex
        w="100%"
        h="full"
        p="2rem"
        display="flex"
        flexDirection="column"
        gap="1rem"
      >
        <Text fontSize="2rem" fontWeight="600" color={theme.color.secondary}>
          Cadastro de Feriado
        </Text>

        <SimpleGrid
          alignItems="center"
          columns={{ base: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
          spacing="1rem"
        >
          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Nome do Feriado
            </FormLabel>
            <Input
              type="text"
              name="name"
              value={form?.name}
              onChange={(event) =>
                setForm({
                  ...form,
                  name: event.target.value,
                })
              }
              onFocus={() =>
                setErrors(ValidationErrorHandling.remove("name", errors))
              }
            />
            {ValidationErrorHandling.hasError("name", errors) && (
              <FormHelperText>
                {ValidationErrorHandling.getErrorMessage("name", errors)}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Data do Feriado
            </FormLabel>
            <Input
              type="date"
              name="date"
              value={
                isValid(form.date)
                  ? Formatter.formatDateForInput(form.date)
                  : form.date
              }
              onChange={(event) => {
                const newDate = event.currentTarget.value
                setForm({ ...form, date: newDate })
              }}
              onFocus={() =>
                setErrors(ValidationErrorHandling.remove("date", errors))
              }
            />
            {ValidationErrorHandling.hasError("date", errors) && (
              <FormHelperText>
                {ValidationErrorHandling.getErrorMessage("date", errors)}
              </FormHelperText>
            )}
          </FormControl>
        </SimpleGrid>

        <Flex mt="1rem">
          <Button
            type="submit"
            minW="95px"
            size="md"
            borderRadius="0.375rem"
            bg="#00335B"
            fontFamily="Raleway"
            fontSize="0.875rem"
            fontWeight="600"
            color="#fff"
            _hover={{ bg: "#00335B" }}
            onClick={() => onSubmit()}
          >
            Salvar
          </Button>
        </Flex>
      </Flex>
    </Layout>
  )
}
