export class OrderPix {
  public id: number
  public txId: string
  public key: string
  public value: number
  public copyPasteCode: string
  public image: string
  public status: string
  public createdAt: Date
  public expiresAt: Date
  public expiration: number
  public paidAt: Date | null

  public constructor(data: any) {
    this.id = data.id || 0
    this.txId = data.txId
    this.key = data.key
    this.value = data.value
    this.copyPasteCode = data.copyPasteCode
    this.image = data.image
    this.status = data.status
    this.createdAt = data.createdAt
    this.expiresAt = data.expiresAt
    this.expiration = data.expiration
    this.paidAt = data.paidAt
  }
}
