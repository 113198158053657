import { User } from "../user"
import { Charge } from "./Charge"
import { OrderPix } from "./OrderPix"
import { OrderTotal } from "./OrderTotal"
import { Transaction } from "./Transaction"

export class Order {
  private _id: string
  public chargesArr: Charge[] = []
  public charges: Charge[] = []
  public transaction: Transaction | undefined
  public pix: OrderPix | undefined
  public user: User | undefined
  public totals: OrderTotal | undefined

  public isFormated: boolean
  public id: number
  public validator: number
  public type: string
  public name: string
  public email: string
  public phone: string
  public tottem: any
  public redeTransaction: any
  public rede: any
  public paymentMethod: string
  public status: string
  public createdAt: string
  public paidAt: string
  public pixChecked: string

  public paymentMethodFormated: string
  public statusFormated: string
  public createdAtFormated: string
  public paidAtFormated: string

  public constructor(data: any = {}) {
    this._id = data.id || data._id

    this.id = data.id

    if (data.user) {
      this.user = new User(data.user)
    }

    this.status = data.status

    this.createdAt = data.createdAt

    this.name = data.name || ""
    this.email = data.email || ""
    this.phone = data.phone || ""

    if (data.charges) {
      for (const charge of data.charges) {
        this.charges.push(new Charge(charge))
      }
    }

    this.isFormated = data.isFormated || false

    this.validator = data.validator
    this.type = data.type

    this.pixChecked = data.pixChecked

    this.statusFormated = data.statusFormated
    this.createdAtFormated = data.createdAtFormated

    this.paidAt = data.paidAt
    this.paymentMethod = data.paymentMethod

    if (data.transaction) {
      this.transaction = new Transaction(data.transaction)
    }

    if (data.pix) {
      this.pix = new OrderPix(data.pix)
    }

    if (data.totals) {
      this.totals = new OrderTotal(data.totals)
    }

    this.chargesArr = data.chargesArr || []

    this.paidAtFormated = data.paidAtFormated || ""
    this.paymentMethodFormated = data.paymentMethodFormated || ""
  }
}
