import { Vehicle, SearchParams, SearchResult } from "../../entities"
import { VehicleRepository } from "../../repositories"
import UserStorage from "../../util/userStorage"

export class VehicleService {
  public static async search(
    params: SearchParams
  ): Promise<SearchResult<Vehicle>> {
    const token = UserStorage.getToken()
    const result = await VehicleRepository.search(params, token)
    return result
  }

  public static async get(id: number): Promise<Vehicle> {
    const token = UserStorage.getToken()
    return await VehicleRepository.get(id, token)
  }

  public static async create(vehicle: Vehicle): Promise<Vehicle> {
    const token = UserStorage.getToken()
    return await VehicleRepository.create(vehicle, token)
  }

  public static async update(vehicle: Vehicle): Promise<Vehicle> {
    const token = UserStorage.getToken()
    return await VehicleRepository.update(vehicle, token)
  }

  public static async delete(id: string): Promise<boolean> {
    const token = UserStorage.getToken()
    return await VehicleRepository.delete(id, token)
  }

  public static async getByUser(
    userId: number | string | undefined,
    withDeleted?: boolean
  ): Promise<SearchResult<Vehicle>> {
    const token = UserStorage.getToken()
    return await VehicleRepository.getByUser(userId, token, withDeleted)
  }
}
