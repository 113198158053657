import { format, getDay, getMonth, getYear } from "date-fns"

export default class Formatter {
  public static formatCurrency(value: number | undefined) {
    if (!value) return ""

    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    })?.format(value / 100)
  }

  public static formatDate(date: Date | undefined): string {
    if (!date) return ""

    if (typeof date === "string") return date
    return date?.toLocaleDateString("pt-br") || ""
  }

  public static formatDateTime(date: Date): string {
    return (
      date?.toLocaleDateString("pt-br") +
        " " +
        date.toLocaleTimeString("pt-br") || ""
    )
  }

  public static formatStatus(status: string): string {
    if (status === "open") return "Aberto"
    if (status === "pending") return "Pendente"
    if (status === "paid") return "Pago"
    if (status === "complete") return "Finalizado"
    if (status === "completed") return "Finalizado"
    if (status === "free") return "Gratuito"
    if (status === "approved") return "Aprovado"
    if (status === "declined") return "Reprovado"
    if (status === "canceled") return "Cancelado"
    if (status === "fail") return "Falhou"
    return status
  }

  public static formatPaymentMethod(code: string): string {
    if (code === "credit-card") return "Cartão de Crédito"
    if (code === "debit-card") return "Cartão de Débito"
    if (code === "pix") return "Pix"
    if (code === "cash") return "Dinheiro"
    if (code === "credit-card-pinpad") return "Cartão de Crédito"
    if (code === "debit-card-pinpad") return "Cartão de Débito"
    if (code === "balance") return "Crédito"
    if (code === "vvp") return "VVP"
    return code
  }

  public static formatDateForInput(date: number | string | Date): string {
    const dateFormated = new Date(date)

    if (isNaN(dateFormated.getTime())) return ""

    return format(dateFormated, "yyyy-MM-dd")
  }

  public static formatOrderType(type: string): string {
    if (type === "charge") return "Cobrança"
    if (type === "balance") return "Crédito"
    return type
  }

  public static formatBlockType(type: string): string {
    if (type === "no") return "Não Bloqueado"
    if (type === "temporary") return "Bloqueio Temporário"
    if (type === "forever") return "Bloqueado"
    if (type === "allowed") return "Liberado"

    return type
  }

  public static formatNumber(n: number): string {
    return new Intl.NumberFormat("pt-BR").format(n)
  }
}
