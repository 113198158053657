export class Holiday {
  id: number
  name: string
  date: string
  createdAt: Date
  deletedAt: Date

  constructor(data: any = {}) {
    this.id = data.id || 0
    this.name = data.name || ""
    this.date = data.date || ""
    this.createdAt = data.createdAt || new Date()
    this.deletedAt = data.deletedAt || new Date()
  }
}
