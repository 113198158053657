import React from "react"
import { Route, Routes } from "react-router-dom"

import RegisterPage from "./register/RegisterPage"
import RecoveryPasswordPage from "./recovery-password/RecoveryPasswordPage"
import ChargeListPage from "./csg/charge/list/ChargeListPage"
import ChargeFormPage from "./csg/charge/form/ChargeFormPage"
import DashboardPage from "./csg/dashboard/DashboardPage"
import VehicleTransferListPage from "./csg/vehicleTransfer/list/VehicleTransferListPage"
import VehicleTransferFormPage from "./csg/vehicleTransfer/form/VehicleTransferFormPage"
import UserListPage from "./csg/user/list/UserListPage"
import UserFormPage from "./csg/user/form/UserFormPage"
import CustomerListPage from "./csg/customer/list/CustomerListPage"
import CustomerFormPage from "./csg/customer/form/CustomerFormPage"
import BalanceReturnFormPage from "./csg/balanceReturn/form/BalanceReturnFormPage"
import BalanceReturnListPage from "./csg/balanceReturn/list/BalanceReturnListPage"
import PaymentListPage from "./payment/list/PaymentListPage"
import Login from "./login/Login"
import ChargesDescriptionPage from "./csg/chargesDescription/ChargesDescriptionPage"
import SecurityListPage from "./csg/security/list/SecurityListPage"
import ChargeReceiptPage from "./csg/charge/receipt/ChargeReceiptPage"
import HelpPage from "./csg/help/HelpPage"
import PaymentBsoListPage from "./payment/bso/PaymentBsoListPage"
import BalanceReturnBulkDetailPage from "./csg/balanceReturn/bulk/detail/BalanceReturnBulkDetailPage"

import PaymentDetailPage from "./payment/detail/PaymentDetailPage"

import HolidayListPage from "./csg/holiday/list/HolidayListPage"
import HolidayFormPage from "./csg/holiday/form/HolidayFormPage"
import NotificationListPage from "./csg/notification/list/NotificationListPage"
import NotificationFormPage from "./csg/notification/form/NotificationFormPage"

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<ChargeListPage />} />
      <Route path="/dashboard" element={<DashboardPage />} />

      <Route path="/customer" element={<CustomerListPage />} />
      <Route path="/customer/:id" element={<CustomerFormPage />} />

      <Route path="/charge" element={<ChargeListPage />} />
      <Route path="/charge/receipt/:id" element={<ChargeReceiptPage />} />
      <Route path="/charge/:id" element={<ChargeFormPage />} />

      <Route path="/holiday" element={<HolidayListPage />} />
      <Route path="/holiday/:id" element={<HolidayFormPage />} />

      <Route path="/notification" element={<NotificationListPage />} />
      <Route path="/notification/:id" element={<NotificationFormPage />} />

      <Route path="/vehicle/transfer" element={<VehicleTransferListPage />} />
      <Route
        path="/vehicle/transfer/:id"
        element={<VehicleTransferFormPage />}
      />

      <Route path="/user" element={<UserListPage />} />
      <Route path="/user/:id" element={<UserFormPage />} />

      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/recovery-password" element={<RecoveryPasswordPage />} />

      <Route path="/balance-return" element={<BalanceReturnListPage />} />
      <Route path="/balance-return/:id" element={<BalanceReturnFormPage />} />
      <Route
        path="/balance-return/bulk/:id"
        element={<BalanceReturnBulkDetailPage />}
      />

      <Route path="/payment" element={<PaymentListPage />} />
      <Route path="/payment/bso" element={<PaymentBsoListPage />} />
      <Route path="payment/:id" element={<PaymentDetailPage />} />

      <Route path="/charge-description" element={<ChargesDescriptionPage />} />

      <Route path="/security" element={<SecurityListPage />} />

      <Route path="/help" element={<HelpPage />} />
    </Routes>
  )
}

export default Router
