import React from "react"
import {
  Link as ChakraLink,
  Box,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Divider,
  Image,
} from "@chakra-ui/react"
import { BsList } from "react-icons/bs"
import { Link as RouterLink } from "react-router-dom"

import UserStorage from "../../../../util/userStorage"

export default function MobileMenu() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <IconButton
        alignSelf="flex-start"
        aria-label="Menu Collapse"
        //icon={<RxHamburgerMenu />}
        icon={<BsList />}
        bg="#fff"
        _hover={{ bg: "#fff", color: "#114B8F" }}
        color="#114B8F"
        fontSize="2rem"
        transition="0.2s"
        onClick={onOpen}
      ></IconButton>
      <Drawer placement="top" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px" color="#114B8F" bg="#fff">
            <Image
              maxH="2.41rem"
              src="/images/header/HeaderLogo.png"
              alt="Logotipo da CSG"
              transition="0.2s"
              _hover={{ opacity: 0.8 }}
            />
            <DrawerCloseButton fontSize="1.25rem" />
          </DrawerHeader>
          <DrawerBody>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              mt="1rem"
              textDecoration="none"
              className="drawer-links"
            >
              <RouterLink to="/">Dashboard</RouterLink>
              <RouterLink to="/customer">Clientes</RouterLink>
              <RouterLink to="/charge">Cobranças</RouterLink>
              <RouterLink to="/vehicle/transfer">Transferências</RouterLink>
              <RouterLink to="/balance-return">Resgate de Saldo</RouterLink>
              <RouterLink to="/user">Usuários</RouterLink>
              <RouterLink to="/security">Segurança</RouterLink>
              <RouterLink to="/holiday">Feriados</RouterLink>
            </Box>
            <Divider
              orientation="horizontal"
              my="1rem"
              bg="rgb(160, 160, 160)"
            />
            <Box display="flex" flexDirection="column" gap={2} mb="1rem">
              <ChakraLink
                href="https://commotion.page/view/sFv4q5uqO1bNKBWXUNP0"
                target="_blank"
              >
                Ajuda
              </ChakraLink>
              <ChakraLink href="/login" onClick={() => UserStorage.logout()}>
                Sair
              </ChakraLink>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
