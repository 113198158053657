import { User } from "../user"
import { VehicleTransfer } from "./VehicleTransfer"

export class Vehicle {
  public id: number
  public user: User | undefined
  public licensePlate: string
  public manufacturer: string
  public model: string
  public color: string
  public createdAt: Date
  public updatedAt: Date
  public deletedAt: Date
  public transfers: VehicleTransfer[] = []
  public createdAtFormated: string
  public updatedAtFormated: string
  public isRegular: boolean
  public isActive: boolean

  public status?: string

  public constructor(data: any) {
    this.id = data.id

    if (data.user) {
      this.user = new User(data.user)
    }

    this.licensePlate = data.licensePlate

    this.manufacturer = data.manufacturer

    this.model = data.model

    this.color = data.color

    this.createdAt = data.createdAt

    this.updatedAt = data.updatedAt

    this.deletedAt = data.deletedAt

    if (data.status) this.status = data.status

    if (data.transfers) {
      for (const transfer of data.transfers) {
        this.transfers.push(new VehicleTransfer(transfer))
      }
    }

    this.createdAtFormated = data.createdAtFormated
    this.updatedAtFormated = data.updatedAtFormated

    this.isRegular = data.isRegular === true
    this.isActive = data.isActive === true
  }
}
