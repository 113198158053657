import React, { useEffect, useState } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"

import Layout from "../../../../components/layout/Layout"
import Breadcrumbs from "../../../../components/layout/breadcrumbs/Breadcrumbs"
import {
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Flex,
  SimpleGrid,
  Button,
  useToast,
  Textarea,
} from "@chakra-ui/react"
import { AiOutlineArrowLeft } from "react-icons/ai"

import { Charge, CheckoutInput, SearchParams } from "../../../../entities"
import { User } from "../../../../entities"
import { ValidationError, ValidationErrorHandling } from "../../../../util"
import { ChargeService, OrderService } from "../../../../services"
import { UserService } from "../../../../services"

import theme from "../../../../config/Themes"
import Formatter from "../../../../util/formatter"
import UserStorage from "../../../../util/userStorage"
import Loader from "../../../../components/Loader/Loader"

export default function NotificationFormPage() {
  const params = useParams()
  const navigate = useNavigate()
  const toast = useToast()

  const [form, setForm] = useState(new Charge({}))

  const [, setUsers] = useState<Array<User>>([])

  const [errors, setErrors] = useState<ValidationError[]>([])
  const [loading, setLoading] = useState(false)

  const [canPayByCash, setCanPayByCash] = useState(false)
  const [canPayByVVP, setCanPayByVVP] = useState(false)

  useEffect(() => {
    const id = params.id ? parseInt(params.id) : 0

    loadUsers()

    if (id) {
      load(id)
    }

    const roleCode = UserStorage.get()?.role?.code
    if (
      ["admin", "financial", "operational"].findIndex(
        (code) => code === roleCode
      ) >= 0
    ) {
      setCanPayByCash(true)
    }
    if (
      ["admin", "financial", "operational"].findIndex(
        (code) => code === roleCode
      ) >= 0
    ) {
      setCanPayByVVP(true)
    }

    // eslint-disable-next-line
  }, [params.id])

  const load = async (id: number) => {
    setLoading(true)
    ChargeService.get(id)
      .then((charge: Charge) => {
        setForm(charge)
      })
      .finally(() => setLoading(false))
  }

  const loadUsers = () => {
    UserService.search(new SearchParams()).then((result) => {
      setUsers(result.data)
    })
  }

  const changeStatus = (paymentMethod: string) => {
    const resp = window.confirm(
      `Deseja confirmar o pagamento em ${Formatter.formatPaymentMethod(
        paymentMethod
      )}?`
    )
    if (!resp) return

    const input = new CheckoutInput({
      type: "charge",
      licensePlates: [form.licensePlate],
      chargeIds: [form.id],
      name: "",
      email: "",
      phone: "",
      paymentMethodCode: paymentMethod,
    })

    setLoading(true)
    OrderService.checkout(input)
      .then((order) => {
        console.log("order", order)
        toast({
          title: "Pagamento adicionado com sucesso",
          status: "success",
          duration: 2000,
        })

        window.open(`/charge/receipt/${form.id}`)

        navigate("/charge")
      })
      .catch((error) =>
        toast({
          title: error?.response?.data?.message,
          status: "error",
        })
      )
      .finally(() => setLoading(false))
  }

  return (
    <Layout>
      {/* {loading && <Loader />} */}
      <Breadcrumbs
        items={[
          { label: "Dashboard", link: "/" },
          { label: "Notificações", link: "/notification" },
          {
            label: "Cadastro de Notificações",
            link: `/notification/${form.id}`,
          },
        ]}
        additionalHeaderInfo={
          <>
            <Link to="/notification">
              <button>
                <AiOutlineArrowLeft />
              </button>
            </Link>
          </>
        }
      />
      <Flex
        w="100%"
        h="full"
        p="2rem"
        display="flex"
        flexDirection="column"
        gap="1rem"
      >
        <Text fontSize="2rem" fontWeight="600" color={theme.color.secondary}>
          Cadastro de Notificação
        </Text>

        <SimpleGrid
          alignItems="center"
          columns={{ base: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
          spacing="1rem"
        >
          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Título da Notificação
            </FormLabel>
            <Input
              type="text"
              name="notificationTitle"
              placeholder=""
              // value={form.notificationTitle}
              // onChange={(event) =>
              //   setForm({
              //     ...form,
              //     notificationTitle: parseInt(event.currentTarget.value),
              //   })
              // }
              onFocus={() =>
                setErrors(
                  ValidationErrorHandling.remove("notificationTitle", errors)
                )
              }
              readOnly
            />
            {ValidationErrorHandling.hasError("notificationTitle", errors) && (
              <FormHelperText>
                {ValidationErrorHandling.getErrorMessage(
                  "notificationTitle",
                  errors
                )}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              SMS
            </FormLabel>
            <Input
              type="text"
              name="sms"
              placeholder=""
              // value={form.sms}
              // onChange={(event) =>
              //   setForm({
              //     ...form,
              //     sms: parseInt(event.currentTarget.value),
              //   })
              // }
              onFocus={() =>
                setErrors(ValidationErrorHandling.remove("sms", errors))
              }
              readOnly
            />
            {ValidationErrorHandling.hasError("sms", errors) && (
              <FormHelperText>
                {ValidationErrorHandling.getErrorMessage("sms", errors)}
              </FormHelperText>
            )}
          </FormControl>
        </SimpleGrid>

        <SimpleGrid
          alignItems="center"
          columns={{ base: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
          spacing="1rem"
        >
          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Push
            </FormLabel>
            <Input
              type="text"
              name="push"
              placeholder=""
              // value={form.push}
              // onChange={(event) =>
              //   setForm({
              //     ...form,
              //     push: parseInt(event.currentTarget.value),
              //   })
              // }
              onFocus={() =>
                setErrors(ValidationErrorHandling.remove("push", errors))
              }
              readOnly
            />
            {ValidationErrorHandling.hasError("push", errors) && (
              <FormHelperText>
                {ValidationErrorHandling.getErrorMessage("push", errors)}
              </FormHelperText>
            )}
          </FormControl>
        </SimpleGrid>

        <SimpleGrid
          alignItems="center"
          columns={{ base: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
          spacing="1rem"
        >
          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              E-mail
            </FormLabel>
            <Textarea placeholder="Digite seu E-mail aqui..." />
          </FormControl>
        </SimpleGrid>

        <Flex mt="1rem">
          <Button
            type="submit"
            minW="95px"
            size="md"
            borderRadius="0.375rem"
            bg="#00335B"
            fontFamily="Raleway"
            fontSize="0.875rem"
            fontWeight="600"
            color="#fff"
            _hover={{ bg: "#00335B" }}
            onClick={() => load(1)}
          >
            Salvar
          </Button>
        </Flex>
      </Flex>
    </Layout>
  )
}
