import {
  CheckoutInput,
  Order,
  SearchParams,
  SearchResult,
} from "../../entities"
import QueryStringBuilder from "../../util/queryBuilder"
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class OrderRepository {
  public static async search(
    params: SearchParams,
    token: string
  ): Promise<SearchResult<Order>> {
    const headers = { Authorization: `Bearer ${token}` }

    const queryStringBuilder = new QueryStringBuilder()
    for (const filter of params.filter) {
      queryStringBuilder.addParameter(filter.key, filter.value)
    }
    const queryString = queryStringBuilder.buildQueryString()

    const response = await client.DoRequest(
      "GET",
      `/order?${queryString}&page=${params.page}&limit=${params.limit}`,
      {},
      headers
    )

    const result = new SearchResult<Order>(response.data, response.info)

    return result
  }

  public static async get(
    id: number | string | undefined,
    token: string
  ): Promise<Order> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest("GET", `/order/${id}`, {}, headers)

    return new Order(response)
  }

  public static async create(order: Order, token: string): Promise<Order> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest("POST", "/order", order, headers)

    return new Order(response)
  }

  public static async update(order: Order, token: string): Promise<Order> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "PUT",
      `/order/$[object Object].id}`,
      order,
      headers
    )

    return new Order(response)
  }

  public static async delete(id: string, token: string): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "DELETE",
      `/order/${id}`,
      {},
      headers
    )

    return response === true
  }

  public static async checkout(
    input: CheckoutInput,
    token: string
  ): Promise<Order> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "POST",
      `/order/checkout`,
      input,
      headers
    )

    console.log("resp", response)

    return new Order(response)
  }
}
