import { useEffect, useState } from "react"
import { Link as ReactRouterLink, useNavigate } from "react-router-dom"
import {
  Button,
  Flex,
  FormControl,
  Input,
  Link as ChakraLink,
  Text,
} from "@chakra-ui/react"
import { GrView } from "react-icons/gr"
import {
  SearchFilter,
  SearchParams,
  SearchResultInfo,
} from "../../../../entities"
import Layout from "../../../../components/layout/Layout"
import Breadcrumbs from "../../../../components/layout/breadcrumbs/Breadcrumbs"
import DataTable from "../../../../components/dataTable/DataTable"
import { FaUmbrellaBeach } from "react-icons/fa6"
import { BsPlusLg } from "react-icons/bs"
import { HolidayService } from "../../../../services/csg/HolidayService"
import { Holiday } from "../../../../entities/csg/Holiday"
import { CiTrash } from "react-icons/ci"
import { format, parseISO } from "date-fns"

export default function HolidayListPage() {
  const navigate = useNavigate()
  const today = new Date()
  const day = ("0" + today.getDate()).slice(-2)
  const month = ("0" + (today.getMonth() + 1)).slice(-2)

  const [holidays, setHolidays] = useState<Holiday[]>([])
  const [resultInfo, setResultInfo] = useState(
    new SearchResultInfo({ pageSize: 20 })
  )
  const [from, setFrom] = useState(
    today.getFullYear() + "-" + month + "-" + day
  )
  const [to, setTo] = useState("")

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")

  useEffect(() => {
    load()
  }, [])

  const getFilters = (): SearchFilter[] => {
    const filters: SearchFilter[] = []

    if (from) {
      filters.push(
        new SearchFilter({
          key: "from",
          value: from,
          operator: "eq",
        })
      )
    }

    if (to) {
      filters.push(
        new SearchFilter({
          key: "to",
          value: to,
          operator: "eq",
        })
      )
    }

    if (name) {
      filters.push(
        new SearchFilter({ key: "name", value: name, operator: "like" })
      )
    }

    return filters
  }

  const load = async (page = 1) => {
    const filters = getFilters()
    const searchParams = new SearchParams(filters, page, 10)

    setLoading(true)
    HolidayService.search(searchParams)
      .then((result) => {
        setHolidays(result.data)
        setResultInfo(result.info)
      })
      .finally(() => setLoading(false))
  }

  const remove = (id: string) => {
    setLoading(true)
    HolidayService.delete(id)
      .then(() => {
        load()
      })
      .finally(() => setLoading(false))
  }

  const renderActions = (params: any) => {
    return (
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="0.5rem"
      >
        <ChakraLink as={ReactRouterLink} to={`/holiday/${params.id}`}>
          <Button
            bg="#E7ECFF"
            color="#00335B"
            transition="0.2s"
            _hover={{ bg: "#114B8F", color: "#fff" }}
          >
            <GrView />
          </Button>
        </ChakraLink>
        <Button
          bg="#E7ECFF"
          color="#00335B"
          transition="0.2s"
          _hover={{ bg: "#114B8F", color: "#fff" }}
          onClick={() => remove(params.id)}
        >
          <CiTrash />
        </Button>
      </Flex>
    )
  }

  const renderDate = (params: any) => {
    const date = parseISO(params.date)

    return format(date, "dd/MM/yyyy")
  }
  const columns = [
    {
      field: "name",
      headerName: "Nome do Feriado",
      mWidth: "55%",
    },
    {
      renderCell: renderDate,
      headerName: "Data do Feriado",
      mWidth: "40%",
    },
    {
      headerName: "Ações",
      renderCell: renderActions,
      mWidth: "65%",
      mAlign: "end",
      mDisplay: "none",
    },
  ]

  return (
    <Layout>
      {/* {loading && <Loader />} */}
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        mb="3rem"
      >
        <Flex direction="column">
          <Breadcrumbs
            items={[
              { label: "Dashboard", link: "/" },
              { label: "Feriados", link: "/holiday" },
            ]}
            additionalHeaderInfo={
              <>
                {/* <Link to="/holiday/0"><button><AiOutlinePlus /></button></Link> */}
              </>
            }
          />
          <Flex gap="0.75rem" alignItems="center">
            <Flex fontSize="2rem" fontWeight="700" color="#114B8F">
              <FaUmbrellaBeach />
            </Flex>
            <Text
              fontSize={{
                base: "1.5rem",
                sm: "1.75rem",
                md: "2rem",
                lg: "2.25rem",
              }}
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
            >
              Feriados
            </Text>
          </Flex>
        </Flex>
        <Flex
          w="auto"
          ml="auto"
          alignSelf="flex-end"
          justifyContent={{
            base: "flex-end",
            sm: "flex-end",
            md: "flex-end",
            lg: "flex-end",
          }}
          gap="1.25rem"
          alignItems="flex-end"
          direction={{ base: "column", sm: "row", md: "row", lg: "row" }}
        >
          <FormControl>
            <Input
              maxW={{ base: "100%", sm: "158px", md: "158px", lg: "158px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              placeholder="Período inicial"
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="date"
              name="from"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <Input
              maxW={{ base: "100%", sm: "158px", md: "158px", lg: "158px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              placeholder="Período final"
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="date"
              name="to"
              value={to}
              onChange={(e) => setTo(e.target.value)}
            />
          </FormControl>

          <FormControl
            w="100%"
            maxW={{ base: "100%", sm: "100%", md: "100%", lg: "648px" }}
            isInvalid={false}
            display="flex"
            justifyContent="flex-end"
            gap="0.5rem"
            flexWrap="wrap"
          >
            <Input
              maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="text"
              name="name"
              placeholder="Nome do feriado"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>

          <Flex gap="1rem">
            <Button
              minW="95px"
              size="md"
              borderRadius="0.375rem"
              bg="#00335B"
              fontFamily="Raleway"
              fontSize="0.875rem"
              fontWeight="600"
              color="#fff"
              _hover={{ bg: "#00335B" }}
              onClick={() => load(1)}
            >
              Buscar
            </Button>
            <Button
              minW="95px"
              size="md"
              borderRadius="0.375rem"
              bg="#F7F8F9"
              fontFamily="Raleway"
              fontSize="0.875rem"
              fontWeight="600"
              color="#1A202C"
              _hover={{ bg: "#F7F8F9" }}
              onClick={() => navigate(`/holiday/0`)}
              display="flex"
              gap="0.5rem"
              alignItems="center"
              justifyContent="center"
            >
              <BsPlusLg />
              Novo
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <DataTable
        checkboxSelection={false}
        rows={holidays}
        columns={columns}
        pagination={resultInfo}
        onPageChange={(page: number) => load(page)}
        loading={loading}
      />
    </Layout>
  )
}
