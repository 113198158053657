import React, { useEffect, useState } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"

import Layout from "../../../../components/layout/Layout"
import Breadcrumbs from "../../../../components/layout/breadcrumbs/Breadcrumbs"
import {
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Flex,
  SimpleGrid,
  RadioGroup,
  ButtonGroup,
  Button,
  useToast,
} from "@chakra-ui/react"
import { AiOutlineArrowLeft } from "react-icons/ai"

import { Charge, CheckoutInput, SearchParams } from "../../../../entities"
import { User } from "../../../../entities"
import { ValidationError, ValidationErrorHandling } from "../../../../util"
import { ChargeService, OrderService } from "../../../../services"
import { UserService } from "../../../../services"

import theme from "../../../../config/Themes"
import Formatter from "../../../../util/formatter"
import UserStorage from "../../../../util/userStorage"
import Loader from "../../../../components/Loader/Loader"

export default function ChargeFormPage() {
  const params = useParams()
  const navigate = useNavigate()
  const toast = useToast()

  const [form, setForm] = useState(new Charge({}))

  const [, setUsers] = useState<Array<User>>([])

  const [errors, setErrors] = useState<ValidationError[]>([])
  const [loading, setLoading] = useState(false)

  const [canPayByCash, setCanPayByCash] = useState(false)
  const [canPayByVVP, setCanPayByVVP] = useState(false)

  useEffect(() => {
    const id = params.id ? parseInt(params.id) : 0

    loadUsers()

    if (id) {
      load(id)
    }

    const roleCode = UserStorage.get()?.role?.code
    if (
      ["admin", "financial", "operational"].findIndex(
        (code) => code === roleCode
      ) >= 0
    ) {
      setCanPayByCash(true)
    }
    if (
      ["admin", "financial", "operational"].findIndex(
        (code) => code === roleCode
      ) >= 0
    ) {
      setCanPayByVVP(true)
    }

    // eslint-disable-next-line
  }, [params.id])

  const load = async (id: number) => {
    setLoading(true)
    ChargeService.get(id)
      .then((charge: Charge) => {
        setForm(charge)
      })
      .finally(() => setLoading(false))
  }

  const loadUsers = () => {
    UserService.search(new SearchParams()).then((result) => {
      setUsers(result.data)
    })
  }

  const changeStatus = (paymentMethod: string) => {
    const resp = window.confirm(
      `Deseja confirmar o pagamento em ${Formatter.formatPaymentMethod(
        paymentMethod
      )}?`
    )
    if (!resp) return

    const input = new CheckoutInput({
      type: "charge",
      licensePlates: [form.licensePlate],
      chargeIds: [form.id],
      name: "",
      email: "",
      phone: "",
      paymentMethodCode: paymentMethod,
    })

    setLoading(true)
    OrderService.checkout(input)
      .then((order) => {
        console.log("order", order)
        toast({
          title: "Pagamento adicionado com sucesso",
          status: "success",
          duration: 2000,
        })

        window.open(`/charge/receipt/${form.id}`)

        navigate("/charge")
      })
      .catch((error) =>
        toast({
          title: error?.response?.data?.message,
          status: "error",
        })
      )
      .finally(() => setLoading(false))
  }

  return (
    <Layout>
      {loading && <Loader />}
      <Breadcrumbs
        items={[
          { label: "Dashboard", link: "/" },
          { label: "Cobranças", link: "/charge" },
          { label: "Descrição da Cobrança", link: `/charge/${form.id}` },
        ]}
        additionalHeaderInfo={
          <>
            <Link to="/charge">
              <button>
                <AiOutlineArrowLeft />
              </button>
            </Link>
          </>
        }
      />
      <Flex
        w="100%"
        h="full"
        p="2rem"
        display="flex"
        flexDirection="column"
        gap="1rem"
      >
        <Text fontSize="2rem" fontWeight="600" color={theme.color.secondary}>
          Descrição da Cobrança
        </Text>

        <SimpleGrid
          alignItems="center"
          columns={{ base: 1, sm: 2, md: 2, lg: 5, xl: 5 }}
          spacing="1rem"
        >
          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              ID da Transação
            </FormLabel>
            <Input
              type="text"
              name="transactionId"
              placeholder=""
              value={form.transactionId}
              onChange={(event) =>
                setForm({
                  ...form,
                  transactionId: parseInt(event.currentTarget.value),
                })
              }
              onFocus={() =>
                setErrors(
                  ValidationErrorHandling.remove("transactionId", errors)
                )
              }
              readOnly
            />
            {ValidationErrorHandling.hasError("transactionId", errors) && (
              <FormHelperText>
                {ValidationErrorHandling.getErrorMessage(
                  "transactionId",
                  errors
                )}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              ID da Trasação Financeira
            </FormLabel>
            <Input
              type="text"
              name="financialTransactionId"
              placeholder=""
              value={form.financialTransactionId}
              onChange={(event) =>
                setForm({
                  ...form,
                  financialTransactionId: parseInt(event.currentTarget.value),
                })
              }
              onFocus={() =>
                setErrors(
                  ValidationErrorHandling.remove(
                    "financialTransactionId",
                    errors
                  )
                )
              }
              readOnly
            />
            {ValidationErrorHandling.hasError(
              "financialTransactionId",
              errors
            ) && (
              <FormHelperText>
                {ValidationErrorHandling.getErrorMessage(
                  "financialTransactionId",
                  errors
                )}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Data Disponível
            </FormLabel>
            <Input
              type="text"
              name="datetimeAvailable"
              placeholder=""
              value={form.datetimeAvailableFormated}
              onChange={(event) =>
                setForm({
                  ...form,
                  datetimeAvailable: new Date(event.currentTarget.value),
                })
              }
              onFocus={() =>
                setErrors(
                  ValidationErrorHandling.remove("datetimeAvailable", errors)
                )
              }
              readOnly
            />
            {ValidationErrorHandling.hasError("datetimeAvailable", errors) && (
              <FormHelperText>
                {ValidationErrorHandling.getErrorMessage(
                  "datetimeAvailable",
                  errors
                )}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Data de Ocorrência
            </FormLabel>
            <Input
              type="text"
              name="datetimeOccurrence"
              placeholder=""
              value={form.datetimeOccurrenceFormated}
              onChange={(event) =>
                setForm({
                  ...form,
                  datetimeOccurrence: new Date(event.currentTarget.value),
                })
              }
              onFocus={() =>
                setErrors(
                  ValidationErrorHandling.remove("datetimeOccurrence", errors)
                )
              }
              readOnly
            />
            {ValidationErrorHandling.hasError("datetimeOccurrence", errors) && (
              <FormHelperText>
                {ValidationErrorHandling.getErrorMessage(
                  "datetimeOccurrence",
                  errors
                )}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Data da criação
            </FormLabel>
            <Input
              type="text"
              name="createdAt"
              placeholder=""
              value={form.createdAtFormated}
              onChange={(event) =>
                setForm({
                  ...form,
                  createdAt: new Date(event.currentTarget.value),
                })
              }
              onFocus={() =>
                setErrors(ValidationErrorHandling.remove("createdAt", errors))
              }
              readOnly
            />
            {ValidationErrorHandling.hasError("createdAt", errors) && (
              <FormHelperText>
                {ValidationErrorHandling.getErrorMessage("createdAt", errors)}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Placa
            </FormLabel>
            <Input
              type="text"
              name="licensePlate"
              placeholder=""
              value={form.licensePlate}
              onChange={(event) =>
                setForm({ ...form, licensePlate: event.currentTarget.value })
              }
              onFocus={() =>
                setErrors(
                  ValidationErrorHandling.remove("licensePlate", errors)
                )
              }
              readOnly
            />
            {ValidationErrorHandling.hasError("licensePlate", errors) && (
              <FormHelperText>
                {ValidationErrorHandling.getErrorMessage(
                  "licensePlate",
                  errors
                )}
              </FormHelperText>
            )}
          </FormControl>
        </SimpleGrid>

        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
          spacing="1rem"
        >
          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              TAG
            </FormLabel>
            <Input
              type="text"
              name="tagIdentifier"
              placeholder=""
              value={form.tagIdentifier}
              onChange={(event) =>
                setForm({ ...form, tagIdentifier: event.currentTarget.value })
              }
              onFocus={() =>
                setErrors(
                  ValidationErrorHandling.remove("tagIdentifier", errors)
                )
              }
              readOnly
            />
            {ValidationErrorHandling.hasError("tagIdentifier", errors) && (
              <FormHelperText>
                {ValidationErrorHandling.getErrorMessage(
                  "tagIdentifier",
                  errors
                )}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Classe de Taxação do Veículo
            </FormLabel>
            <Input
              type="text"
              name="vehicleClassRated"
              placeholder=""
              value={form.vehicleClassRated}
              onChange={(event) =>
                setForm({
                  ...form,
                  vehicleClassRated: event.currentTarget.value,
                })
              }
              onFocus={() =>
                setErrors(
                  ValidationErrorHandling.remove("vehicleClassRated", errors)
                )
              }
              readOnly
            />
            {ValidationErrorHandling.hasError("vehicleClassRated", errors) && (
              <FormHelperText>
                {ValidationErrorHandling.getErrorMessage(
                  "vehicleClassRated",
                  errors
                )}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Tarifa
            </FormLabel>
            <Input
              type="text"
              name="rateAmount"
              placeholder=""
              value={form.rateAmountFormated}
              readOnly
            />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Desconto
            </FormLabel>
            <Input
              type="text"
              name="discount"
              placeholder=""
              value={form.discountFormated}
              readOnly
            />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              DUF
            </FormLabel>
            <Input
              type="text"
              name="discountDuf"
              placeholder=""
              value={form.discountDufFormated}
              readOnly
            />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              DBT
            </FormLabel>
            <Input
              type="text"
              name="discountDbt"
              placeholder=""
              value={form.discountDbtFormated}
              readOnly
            />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Acréscimo
            </FormLabel>
            <Input
              type="text"
              name="fee"
              placeholder=""
              value={form.feeFormated}
              readOnly
            />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Multa
            </FormLabel>
            <Input
              type="text"
              name="fine"
              placeholder=""
              value={form.fineFormated}
              readOnly
            />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Juros
            </FormLabel>
            <Input
              type="text"
              name="interest"
              placeholder=""
              value={form.interestFormated}
              readOnly
            />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Código da Praça
            </FormLabel>
            <Input
              type="text"
              name="plazaCode"
              placeholder=""
              value={form.plazaCode}
              onChange={(event) =>
                setForm({ ...form, plazaCode: event.currentTarget.value })
              }
              onFocus={() =>
                setErrors(ValidationErrorHandling.remove("plazaCode", errors))
              }
              readOnly
            />
            {ValidationErrorHandling.hasError("plazaCode", errors) && (
              <FormHelperText>
                {ValidationErrorHandling.getErrorMessage("plazaCode", errors)}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Descrição da Praça
            </FormLabel>
            <Input
              type="text"
              name="plazaDescription"
              placeholder=""
              value={form.plazaDescription}
              onChange={(event) =>
                setForm({
                  ...form,
                  plazaDescription: event.currentTarget.value,
                })
              }
              onFocus={() =>
                setErrors(
                  ValidationErrorHandling.remove("plazaDescription", errors)
                )
              }
              readOnly
            />
            {ValidationErrorHandling.hasError("plazaDescription", errors) && (
              <FormHelperText>
                {ValidationErrorHandling.getErrorMessage(
                  "plazaDescription",
                  errors
                )}
              </FormHelperText>
            )}
          </FormControl>
        </SimpleGrid>

        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
          spacing="1rem"
        >
          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Código da Faixa
            </FormLabel>
            <Input
              type="text"
              name="laneCode"
              placeholder=""
              value={form.laneCode}
              onChange={(event) =>
                setForm({ ...form, laneCode: event.currentTarget.value })
              }
              onFocus={() =>
                setErrors(ValidationErrorHandling.remove("laneCode", errors))
              }
              readOnly
            />
            {ValidationErrorHandling.hasError("laneCode", errors) && (
              <FormHelperText>
                {ValidationErrorHandling.getErrorMessage("laneCode", errors)}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Status
            </FormLabel>
            <Input
              type="text"
              name="status"
              placeholder=""
              value={form.statusFormated}
              onChange={(event) =>
                setForm({ ...form, status: event.currentTarget.value })
              }
              onFocus={() =>
                setErrors(ValidationErrorHandling.remove("status", errors))
              }
              readOnly
            />
            {ValidationErrorHandling.hasError("status", errors) && (
              <FormHelperText>
                {ValidationErrorHandling.getErrorMessage("status", errors)}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Foi ressincronizado?
            </FormLabel>
            <RadioGroup>{form.isReSynchorization ? "Sim" : "Não"}</RadioGroup>
            {ValidationErrorHandling.hasError("isReSynchorization", errors) && (
              <FormHelperText>
                {ValidationErrorHandling.getErrorMessage(
                  "isReSynchorization",
                  errors
                )}
              </FormHelperText>
            )}
          </FormControl>
        </SimpleGrid>
        {form.status === "paid" && (
          <>
            <h2>Informações de Pagamento</h2>
            <SimpleGrid
              columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 4 }}
              spacing="1rem"
            >
              <FormControl isRequired isInvalid={false}>
                <FormLabel minHeight="3rem" color={theme.color.tertiary}>
                  Pago em
                </FormLabel>
                <Input
                  type="text"
                  placeholder=""
                  value={form?.order?.paidAtFormated}
                  readOnly
                />
              </FormControl>
              <FormControl isRequired isInvalid={false}>
                <FormLabel minHeight="3rem" color={theme.color.tertiary}>
                  Forma de pagamento
                </FormLabel>
                <Input
                  type="text"
                  placeholder=""
                  value={form?.order?.paymentMethodFormated}
                  readOnly
                />
              </FormControl>
              <FormControl isRequired isInvalid={false}>
                <FormLabel minHeight="3rem" color={theme.color.tertiary}>
                  Pago por
                </FormLabel>
                <Input
                  type="text"
                  placeholder=""
                  value={form?.order?.name}
                  readOnly
                />
              </FormControl>
              <FormControl isRequired isInvalid={false}>
                <FormLabel minHeight="3rem" color={theme.color.tertiary}>
                  E-mail
                </FormLabel>
                <Input
                  type="text"
                  placeholder=""
                  value={form?.order?.email}
                  readOnly
                />
              </FormControl>
              <FormControl isRequired isInvalid={false}>
                <FormLabel minHeight="3rem" color={theme.color.tertiary}>
                  Telefone
                </FormLabel>
                <Input
                  type="text"
                  placeholder=""
                  value={form?.order?.phone}
                  readOnly
                />
              </FormControl>
              {form?.order?.pix && (
                <FormControl isRequired isInvalid={false}>
                  <FormLabel minHeight="3rem" color={theme.color.tertiary}>
                    TxID
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder=""
                    value={form?.order?.pix?.txId}
                    readOnly
                  />
                </FormControl>
              )}
            </SimpleGrid>
          </>
        )}
        {form.status === "open" && (
          <>
            <ButtonGroup>
              {canPayByCash && (
                <Button onClick={() => changeStatus("cash")}>
                  Receber pagamento em dinheiro
                </Button>
              )}
              {canPayByVVP && (
                <Button onClick={() => changeStatus("vvp")}>
                  Receber pagamento em vale
                </Button>
              )}
            </ButtonGroup>
          </>
        )}
      </Flex>
    </Layout>
  )
}
